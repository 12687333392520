<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { keyMaster, agencyMethods, masterMethods, checkPermission, checkPermissionName, formattedPostalCode } from '@/state/helpers';
import Swal from 'sweetalert2';
import { renderLineBreaks } from '../../../utils/format';
import { campaignStatus } from '@/config/var-common';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '代理店詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            index: 1,
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                type: {
                    id: ''
                },
                note: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: ''
            },
            listAgencyCP: [],
            listAgencySupports: [],
            listDataMasterCampaignStatus: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false
        };
    },
    mounted() {
        if (!this.checkPermiss(['administrator', 'sale', 'instructor', 'part_time'])) {
            this.$router.push({ path: '/' }).then(() => {
                this.$bvToast.toast(`warning`, {
                    title: `アクセス権限がありません。`,
                    variant: 'warning',
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            });
        }

        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.getListAgencySupport(this.$route.query.id);
        }
    },
    methods: {
        formattedPostalCode,
        checkPermission,
        ...masterMethods,
        ...agencyMethods,
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },
        renderSttText(numberStt) {
            const result = campaignStatus.find((item) => item.id === numberStt);
            return result ? result.text : '';
        },
        getDetail(id) {
            this.loading = true;
            this.detailAgency(id).then((data) => {
                this.listMaster(keyMaster['agency.type']).then((dataAg) => {
                    this.loading = false;
                    this.form = data;
                    this.listAgencyCP = data.campaigns;
                    this.listDataMasterCampaignStatus = dataAg;
                    if (!dataAg.length) return;
                    const getNameTypes = this.listDataMasterCampaignStatus.filter((item) => data.types.find((element) => element == item.id));
                    this.form.types = getNameTypes.map((item) => item.value).join(', ');
                });
            });
        },
        getListMasterAgency() {
            this.listMaster(keyMaster['agency.type']).then((data) => {
                this.listDataMasterCampaignStatus = data;
            });
        },
        goToEdit(path, id, agency_id) {
            this.$router.push({
                path: path,
                query: { id: id, agency_id: agency_id }
            });
        },
        getListAgencySupport(id) {
            this.listAgencySupport(id).then((data) => {
                this.listAgencySupports = data.data;
            });
        },
        formatDateToChinese(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
            const date = new Date(time);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            return `${year} 年 ${month} 月 ${day} 日`;
        },
        deleteObject(data) {
            if (this.form.campaigns && this.form.campaigns.length > 0) {
                Swal.fire({
                    title: '紐付けられているキャンペーンがあるため削除できません。',
                    icon: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#b5b8bf',
                    cancelButtonText: 'キャンセル'
                });
                return;
            }
            Swal.fire({
                title: 'この代理店を削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteAgency(data).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/agency/list' }).then(() => {
                                this.$bvToast.toast(`success`, {
                                    title: `代理店担当者が削除されました。`,
                                    variant: 'success',
                                    toaster: 'b-toaster-top-center',
                                    solid: true
                                });
                            });
                        } else {
                            this.$bvToast.toast(`error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation">
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.date') }}</label>
                                        <div class="col-sm-9">
                                            {{ formatDateToChinese(form.created_at) }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.nameCompany') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.kana_nameCompany') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.kana_name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">法人番号</label>
                                        <div class="col-sm-9">
                                            {{ form.corp_number }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.phone_number') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.address') }}</label>
                                        <div class="col-sm-9">
                                            〒{{ formattedPostalCode(form.postal_code) }}
                                            {{ form.province }}
                                            {{ form.address_1 }}
                                            {{ form.address_2 }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.profile') }}</label>
                                        <div class="col-sm-9">
                                            <p v-html="textLineBreaks(form.profile)"></p>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.types') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.types }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.url') }}</label>
                                        <div class="col-sm-9">
                                            <a :href="form.url" target="_blank">{{ form.url }}</a>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('agency.searchKeyword') }}</label>
                                        <div class="col-sm-9">
                                            <div v-if="form.keywords">
                                                <span v-for="(i, k) in form.keywords" :key="'p' + k">{{ k > 0 ? ', ' : '' }}{{ i }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <h5><b>キャンペーン一覧</b></h5>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>CP名</th>
                                                    <th>進捗ステータス</th>
                                                    <th>CP終了日</th>
                                                    <th>事務局終了日</th>
                                                    <th>担当</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listAgencyCP.length > 0">
                                                <template>
                                                    <tr v-for="(item, k) in listAgencyCP" :key="'c' + k">
                                                        <td>{{ k + 1 }}</td>

                                                        <td>
                                                            {{ item.name }}<br />
                                                            {{ item.name_kana ? item.name_kana : '' }}
                                                        </td>
                                                        <td>
                                                            {{ renderSttText(item.progress_status) }}
                                                        </td>
                                                        <td>
                                                            {{ item.campaign_end_date ? item.campaign_end_date : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.secretariat_end_date ? item.secretariat_end_date : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.person_in_charge ? item.person_in_charge.name : '' }}
                                                        </td>

                                                        <td align="center">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-info"
                                                                @click="goToEdit('/campaign/form/view', item.id)"
                                                                >{{ $t('btn.detail') }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <br />

                                    <h5><b>担当者一覧</b></h5>
                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('agency.manager') }}</th>
                                                    <th>{{ $t('agency.personChargeKana') }}</th>
                                                    <th>{{ $t('agency.department') }}</th>
                                                    <th>{{ $t('agency.email') }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listAgencySupports.length > 0">
                                                <template>
                                                    <tr v-for="item in listAgencySupports" :key="'s' + item.id">
                                                        <td>
                                                            {{ item.name ? item.name : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.kana_name ? item.kana_name : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.department ? item.department : '' }}
                                                        </td>
                                                        <td>{{ item.email ? item.email : '' }}</td>
                                                        <td align="center">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-info border-right-1"
                                                                @click="goToEdit('/agency/support/view', item.id, $route.query.id)"
                                                                >{{ $t('btn.detail') }}</a
                                                            >
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-success"
                                                                @click="goToEdit('/agency/form/support', item.id, $route.query.id)"
                                                                >{{ $t('btn.edit') }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center mt-4">
                                        <button @click="goToEdit('/agency/form/support', '', $route.query.id)" type="button" class="btn btn-primary">
                                            {{ $t('btn.registerPersonInCharge') }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/agency/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission('agency.edit')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/agency/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermission('agency.delete')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
